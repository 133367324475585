<template>
<div class="alert-info" v-if="show">
  <div v-for="(log, index) in logs" v-bind:key="index">{{ log }}</div>
</div>
</template>

<script>
import { formatParams } from "@/services/log";

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      logs: [],
      output: null,
    };
  },
  mounted() {
    this.output = ((message, data) => {
      this.logs.push(`> ${message} ${formatParams(data)}`);
    }).bind(this);
    this.$log.output = this.output;
  },
  methods: {
    capture(log) {
      log.output = this.output;
    }
  }
}
</script>

<style>

</style>