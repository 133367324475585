<template>
<div class="ml-2 mr-2">
  <div class="form-inline sub-mr-2 sub-mt-2">
    <b-form-checkbox v-model="audio">Audio</b-form-checkbox>
    <button class="btn btn-primary" @click="create()">System Default</button>
    <button class="btn btn-primary" @click="create('auto')">Auto Definition</button>
    <button class="btn btn-primary" @click="create('low')">Low Definition</button>
    <button class="btn btn-primary" @click="create('high')">Highest Definition</button>
    <button class="btn btn-danger" @click="stopStream(stream); stream = null">Off</button>
    <button class="btn btn-danger" @click="logs = []">Clear logs</button>
  </div>
  <webrtc-settings
    :current="deviceSettings"
    @selection-saved="setDeviceSettings"
  />
  <debug-group class="mt-2 mb-2" expand>
    <log-output ref="logOutput"/>
    <debug-obj label="Supported Constraints" :objData="supportedConstraints"/>
    <debug-obj label="Stream Info" :objData="streamInfo" expand/>
  </debug-group>
  <br/>
  <video :srcObject.prop="stream" autoplay playsinline :muted="!audio"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('test-gum');
import debugGroup from '../../components/debugGroup.vue';
import DebugObj from '../../components/debugObj.vue';
import { stopStream, getStreamInfo, getUserMedia } from '@/services/mediautils';
import WebrtcSettings from '../../components/webrtcSettings.vue';
import logOutput from '@/components/logOutput.vue';

export default {
  components: { debugGroup, DebugObj, WebrtcSettings, logOutput },
  data() {
    return {
      audio: false,
      stream: null,
      streamInfo: null,
      deviceSettings: null,
    }
  },
  computed: {
    supportedConstraints() {
      return navigator.mediaDevices.getSupportedConstraints();
    }
  },
  mounted() {
    // overriding console output to read on mobile devices
    if (this.$refs.logOutput) {
      this.$refs.logOutput.capture(log);
      this.output = this.$refs.logOutput.output;
    }
  },
  beforeDestroy() {
    stopStream(this.stream);
  },
  methods: {
    stopStream,
    async setDeviceSettings(s) {
      log.log("setDeviceSettings", s);
      this.deviceSettings = s;
      this.create();
    },
    getAudioConstraints() {
      if (this.audio)
        //return { latency: 0 };
        return true;
        /*
        return {
          autoGainControl: false,
          noiseSuppression: true,
          echoCancellation: false,
          sampleRate: 44100,
        };
        */
      return undefined;
    },
    async create(definition) {
      log.log("create", definition);
      stopStream(this.stream);
      this.stream = await getUserMedia({video:true, audio:this.getAudioConstraints(), definition, logOutput:this.output}, this.deviceSettings);
      this.streamInfo = getStreamInfo(this.stream);
    },
  }
}
</script>

<style scoped>

video {
  max-width: 100%;
}

</style>